import React from "react";
import Layout from "../../components/layout";
import data from '../../data/testing-service-consulting';
import ASABEX613 from '../resources/ASABE-X613-Cabin-Filtration.pdf';
import CPFT from "../resources/CAF 4 Cab Protection Factors Template ISO R20245.pdf";
import FFPF from "../resources/FIT FACTOR-PROTECTION FACTOR.pdf"
import { Helmet } from 'react-helmet';

export default () => {
    const { title, content } = data;
    const { section1, section2 } = content;
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${title} | Clean Air Filter`}</title>
        </Helmet>
        <section>
          <h1>
            <span className="tail">
              <span className="head">{section1.h1.head}</span>{" "}
              {section1.h1.tail}
            </span>
          </h1>
          <article className="w-60">
            {/* <p>{section1.p1}</p> */}

            {/* <ul>
                    {data.content.section1.ul.map((item, index) => <li key={index}><p dangerouslySetInnerHTML={{ __html: item }}></p></li>)}
                </ul> */}
            {/* <p>{section1.p2}</p> */}
          </article>
          {/* </section>
        <section> */}
          <h2>{section2.h2}</h2>
          <p>{data.content.section2.p}</p>
          <ul>
            {data.content.section2.ul.map((item, index) => (
              <li key={index}>
                <p dangerouslySetInnerHTML={{ __html: item }}></p>
              </li>
            ))}
          </ul>
        </section>
        <section>
          <h2>{data.content.sectionFilterFitTest.h2}</h2>
          <p>{data.content.sectionFilterFitTest.p}</p>
          {/* FIT FACTOR-PROTECTION FACTOR.pdf */}
          <p>
            <a
              className="download-link"
              href={FFPF}
              target={"_blank"}
              rel="noreferrer"
            >
              {data.content.sectionFilterFitTest.a0}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              href={CPFT}
              target={"_blank"}
              rel="noreferrer"
            >
              {data.content.sectionFilterFitTest.a1}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              href={ASABEX613}
              target={"_blank"}
              rel="noreferrer"
            >
              {data.content.sectionFilterFitTest.a2}
            </a>
          </p>
        </section>
      </Layout>
    )
};